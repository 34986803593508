import store from "@/store";
import HomeServices from "@/apiServices/HomeServices";
import CommonServices from "@/apiServices/CommonServices";

export default {
  namespaced: true,

  state: {
    Countries: [],
    States: [],
    Cities: [],
    CountriesAndCities: [],
    CourseLevels: [],
    CourseCategories: [],
    DocumentTypes: [],
    StudentDocumentTypes: [],
    VisaDocumentTypes: [],
    StudentVisaDocumentTypes: [],
    UniversityList: [],
    TuitionFeesFilterValues: [],
    TuitionUSDFeesFilterValues: [],
  },

  mutations: {
    ON_SET_COUNTRIES: (state, payload) => {
      state.Countries = payload;
    },
    ON_SET_STATES: (state, payload) => {
      state.States = payload;
    },
    ON_SET_CITIES: (state, payload) => {
      state.Cities = payload;
    },
    ON_SET_COUNTRIES_CITIES: (state, payload) => {
      state.CountriesAndCities = payload;
    },
    ON_SET_COURSE_LEVELS: (state, payload) => {
      state.CourseLevels = payload;
    },
    ON_SET_COURSE_CATEGORIES: (state, payload) => {
      state.CourseCategories = payload;
    },
    ON_SET_DOCUMENT_TYPES: (state, payload) => {
      state.DocumentTypes = payload;
    },
    ON_SET_STUDENT_DOCUMENT_TYPES: (state, payload) => {
      state.StudentDocumentTypes = payload
    },
    ON_SET_VISA_DOCUMENT_TYPES: (state, payload) => {
      state.VisaDocumentTypes = payload;
    },
    ON_SET_STUDENT_VISA_DOCUMENT_TYPES: (state, payload) => {
      state.StudentVisaDocumentTypes = payload
    },
    ON_SET_UNIVERSITY_LIST: (state, payload) => {
      state.UniversityList = payload;
    },
    ON_SET_TUITION_FEES_FILTER_VALUES: (state, payload) => {
      state.TuitionFeesFilterValues = payload
    },
    ON_SET_TUITION_USD_FEES_FILTER_VALUES: (state, payload) => {
      state.TuitionUSDFeesFilterValues = payload
    },
  },

  actions: {
    onGetUniversityList: async ({ dispatch, commit, state }, payload) => {
      await commit("ON_SET_UNIVERSITY_LIST", []);
      HomeServices.getSearchList()
        .then(async (response) => {
          const { data } = response.data.data;
          if (response["data"] && response.data.status) {
            await commit("ON_SET_UNIVERSITY_LIST", data);
          } else {
            store.dispatch("AppSetting/showAdminErrorMessage", {
              message: data.message,
            });
          }
        })
        .catch((error) => {
          console.error("Catch Error on onGetUniversityList", error);
        })
        
    },
    onGetTuitionFeesFilterValues: async ({ dispatch, commit, state }, payload) => {
      await commit('ON_SET_TUITION_FEES_FILTER_VALUES', [])
      HomeServices.getTuitionFeesFilterValues(payload)
        .then(async (response) => {
          const { data: { data } } = response;
          if (response['data'] && response.data.status) {
            await commit('ON_SET_TUITION_FEES_FILTER_VALUES', data)
          } else {
            store.dispatch("AppSetting/showAdminErrorMessage", { message: data.message });
          }
        }).catch((error) => {
          console.error('Catch Error on onGetTutionFeesFilterValues', error);
        })
    },
    onGetTuitionUSDFeesFilterValues: async ({ dispatch, commit, state }, payload) => {
      await commit('ON_SET_TUITION_USD_FEES_FILTER_VALUES', [])
      HomeServices.getTuitionUSDFeesFilterValues(payload)
        .then(async (response) => {
          const { data: { data } } = response;
          if (response['data'] && response.data.status) {
            await commit('ON_SET_TUITION_USD_FEES_FILTER_VALUES', data)
          } else {
            store.dispatch("AppSetting/showAdminErrorMessage", { message: data.message });
          }
        }).catch((error) => {
          console.error('Catch Error on onGetTutionFeesFilterValues', error);
        })
    },
    onGetCountries: async ({ dispatch, commit, state }, payload) => {
      await commit("ON_SET_COUNTRIES", []);
      HomeServices.getAllCountries(payload)
        .then(async (response) => {
          const { data } = response.data;
          if (response["data"] && response.data.status) {
            await commit("ON_SET_COUNTRIES", data);
          } else {
            store.dispatch("AppSetting/showAdminErrorMessage", {
              message: data.message,
            });
          }
        })
        .catch((error) => {
          console.error("Catch Error on onGetCountries", error);
        })
        
    },
    onGetStates: async ({ dispatch, commit, state }, payload) => {
      await commit("ON_SET_STATES", []);
      HomeServices.getAllStates(payload)
        .then(async (response) => {
          const { data } = response.data;
          if (response["data"] && response.data.status) {
            await commit("ON_SET_STATES", data);
          } else {
            store.dispatch("AppSetting/showAdminErrorMessage", {
              message: data.message,
            });
          }
        })
        .catch((error) => {
          console.error("Catch Error on onGetStates", error);
        })
        
    },
    onGetCities: async ({ dispatch, commit, state }, payload) => {
      await commit("ON_SET_CITIES", []);
      HomeServices.getAllCities(payload)
        .then(async (response) => {
          const { data } = response.data;
          if (response["data"] && response.data.status) {
            await commit("ON_SET_CITIES", data);
          } else {
            store.dispatch("AppSetting/showAdminErrorMessage", {
              message: data.message,
            });
          }
        })
        .catch((error) => {
          console.error("Catch Error on onGetCities", error);
        })
        
    },
    onGetCountriesAndCities: async (
      { dispatch, commit, state },
      payload
    ) => {
      await commit("ON_SET_COUNTRIES_CITIES", []);
      HomeServices.getCountries(payload)
        .then(async (response) => {
          const { data } = response.data;
          if (response["data"] && response.data.status) {
            await commit("ON_SET_COUNTRIES_CITIES", data);
          } else {
            store.dispatch("AppSetting/showAdminErrorMessage", {
              message: data.message,
            });
          }
        })
        .catch((error) => {
          console.error("Catch Error on onGetCountriesAndCities", error);
        })
        
    },
    onGetCourseLevels: async ({ dispatch, commit, state }, payload) => {
      await commit("ON_SET_COURSE_LEVELS", []);
      HomeServices.getCourseLevels(payload)
        .then(async (response) => {
          const { data: { data } } = response;
          if (response["data"] && response.data.status) {
            await commit("ON_SET_COURSE_LEVELS", data);
          } else {
            store.dispatch("AppSetting/showAdminErrorMessage", {
              message: data.message,
            });
          }
        })
        .catch((error) => {
          console.error("Catch Error on onGetCourseLevels", error);
        })
        
    },
    onGetCourseCategories: async (
      { dispatch, commit, state },
      payload
    ) => {
      await commit("ON_SET_COURSE_CATEGORIES", []);
      HomeServices.getCourseCategories(payload)
        .then(async (response) => {
          const {
            data: { data },
          } = response;
          if (response["data"] && response.data.status) {
            await commit("ON_SET_COURSE_CATEGORIES", data);
          } else {
            store.dispatch("AppSetting/showAdminErrorMessage", {
              message: data.message,
            });
          }
        })
        .catch((error) => {
          console.error("Catch Error on onGetCourseCategories", error);
        })
        
    },
    onGetDocumentTypes: async ({ dispatch, commit, state }, payload) => {
      await commit("ON_SET_DOCUMENT_TYPES", []);
      CommonServices.getAllDocumentTypes()
        .then(async (response) => {
          const { data } = response.data;
          if (response["data"] && response.data.status) {
            await commit("ON_SET_DOCUMENT_TYPES", data);
          } else {
            store.dispatch("AppSetting/showAdminErrorMessage", {
              message: data.message,
            });
          }
        })
        .catch((error) => {
          console.error("Catch Error on onGetDocumentTypes", error);
        })
        
    },
    onGetStudentDocumentTypes: async ({ dispatch, commit, state }, payload) => {
      await commit("ON_SET_STUDENT_DOCUMENT_TYPES", []);
      CommonServices.getAllStudentDocumentTypes()
        .then(async (response) => {
          const { data } = response.data;
          if (response["data"] && response.data.status) {
            await commit("ON_SET_STUDENT_DOCUMENT_TYPES", data);
          } else {
            store.dispatch("AppSetting/showAdminErrorMessage", {
              message: data.message,
            });
          }
        })
        .catch((error) => {
          console.error("Catch Error on onGetStudentDocumentTypes", error);
        })
        
    },
    onGetVisaDocumentTypes: async ({ dispatch, commit, state }, payload) => {
      await commit("ON_SET_VISA_DOCUMENT_TYPES", []);
      CommonServices.getAllVisaDocumentTypes()
        .then(async (response) => {
          const { data } = response.data;
          if (response["data"] && response.data.status) {
            await commit("ON_SET_VISA_DOCUMENT_TYPES", data);
          } else {
            store.dispatch("AppSetting/showAdminErrorMessage", {
              message: data.message,
            });
          }
        })
        .catch((error) => {
          console.error("Catch Error on onGetVisaDocumentTypes", error);
        })
        
    },
    onGetStudentVisaDocumentTypes: async ({ dispatch, commit, state }, payload) => {
      await commit("ON_SET_STUDENT_VISA_DOCUMENT_TYPES", []);
      CommonServices.getAllStudentVisaDocumentTypes()
        .then(async (response) => {
          const { data } = response.data;
          if (response["data"] && response.data.status) {
            await commit("ON_SET_STUDENT_VISA_DOCUMENT_TYPES", data);
          } else {
            store.dispatch("AppSetting/showAdminErrorMessage", {
              message: data.message,
            });
          }
        })
        .catch((error) => {
          console.error("Catch Error on onGetStudentVisaDocumentTypes", error);
        })
        
    },
    
  },

  getters: {
    getCountries: (state) => state.Countries,
    getStates: (state) => state.States,
    getCities: (state) => state.Cities,
    getCountriesAndCities: (state) => state.CountriesAndCities,
    getDegrees: (state) => state.Degrees,
    getDisciplines: (state) => state.Disciplines,
    getCourseLevels: (state) => state.CourseLevels,
    getCourseCategories: (state) => state.CourseCategories,
    getDocumentTypes: (state) => state.DocumentTypes,
    getStudentDocumentTypes: (state) => state.StudentDocumentTypes,
    getVisaDocumentTypes: (state) => state.VisaDocumentTypes,
    getStudentVisaDocumentTypes: (state) => state.StudentVisaDocumentTypes,
    getUniversityList: (state) => state.UniversityList,
    getTuitionFeesFilterValues: state => state.TuitionFeesFilterValues,
    getTuitionUSDFeesFilterValues: state => state.TuitionUSDFeesFilterValues,
    getNotifications: state => state.Notifications
  },
};
