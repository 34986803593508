import CommonServices from "@/apiServices/CommonServices";

export default {
    namespaced: true,
    state: {
        user_authenticated: false,
        user_details: {},
        assigned_students: [],
        notifications: [],
        chats: [],
        unread_notifications: [],
        unread_notifications_count: 0,
        unread_messages_count: 0
    },
    getters: {
        getUserDetails: state => state.user_details,
        getAssignedStudents: state => state.assigned_students
    },
    mutations: {
        SET_USER_AUTHENTICATED(state, val) {
            state.user_authenticated = val
        },
        SET_USER_DETAILS(state, val) {
            state.user_details = val
        },
        SET_ASSIGNED_STUDENTS(state, val) {
            state.assigned_students = val
        },
        ON_SET_NOTIFICATIONS: (state, payload) => {
            state.notifications = payload
        },
        ON_SET_CHATS: (state, payload) => {
            state.chats = payload
        },
        ON_SET_UNREAD_NOTIFICATIONS: (state, payload) => {
            state.unread_notifications = payload
        },
        ON_SET_UNREAD_NOTIFICATIONS_COUNT: (state, payload) => {
            state.unread_notifications_count = payload
        },
        ON_SET_UNREAD_MESSAGES_COUNT: (state, payload) => {
            state.unread_messages_count = payload
        },
    },
    actions: {
        onGetAssignedStudents: async ({ dispatch, commit, state }, { ...payload }) => {
            await commit('SET_ASSIGNED_STUDENTS', []);
            CommonServices.getAssignedStudents()
                .then(async (response) => {
                    if (response.data.status) {
                        await commit('SET_ASSIGNED_STUDENTS', response.data.data)
                    } else {
                        console.log(response.data);
                    }
                })
                .catch(err => {
                    console.log('Error in getting assigned students ', err);
                })
        },
        onGetNotifications: async ({ dispatch, commit, state }, payload) => {
            await commit("ON_SET_NOTIFICATIONS", []);
            CommonServices.getNotifications(payload)
                .then(async (response) => {
                    const { data } = response.data;
                    if (response["data"] && response.data.status) {
                        await commit("ON_SET_NOTIFICATIONS", data);
                    } else {
                        store.dispatch("AppSetting/showAdminErrorMessage", {
                            message: data.message,
                        });
                    }
                })
                .catch((error) => {
                    console.log("Catch Error on onGetNotifications", error);
                })
        },
        onGetChats: async ({ dispatch, commit, state }, payload) => {
            await commit("ON_SET_CHATS", []);
            CommonServices.getChatConnections()
                .then(async (response) => {
                    const { data } = response.data;
                    if (response["data"] && response.data.status) {
                        await commit("ON_SET_CHATS", data);
                    } else {
                        store.dispatch("AppSetting/showAdminErrorMessage", {
                            message: data.message,
                        });
                    }
                })
                .catch((error) => {
                    console.log("Catch Error on onGetNotifications", error);
                })
        }
    },
}